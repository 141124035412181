import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import ErrorPage from "./views/error-page/Main.vue";

const viewRotes: RouteRecordRaw[] = [
  // project views
  { path: "dashboard", component: () => import("./views/dashboard/Main.vue") },
  {
    path: "configuration",
    component: () => import("./views/configuration/Main.vue")
  },
  {
    path: "action-log",
    component: () => import("./views/action-log/Main.vue")
  },
  { path: "role", component: () => import("./views/role/Main.vue") },
  { path: "user", component: () => import("./views/user/Main.vue") },
  {
    path: "user-wizard",
    component: () => import("./views/user-wizard/Main.vue")
  },
  { path: "category", component: () => import("./views/category/Main.vue") },
  { path: "banner", component: () => import("./views/banner/Main.vue") },
  { path: "album", component: () => import("./views/album/Main.vue") },
  { path: "attribute", component: () => import("./views/attribute/Main.vue") },
  { path: "channel", component: () => import("./views/channel/Main.vue") },
  { path: "product", component: () => import("./views/product/Main.vue") },
  { path: "lottery", component: () => import("./views/lottery/Main.vue") },
  { path: "winner", component: () => import("./views/winner/Main.vue") },
  {
    path: "invoiceRecord",
    component: () => import("./views/invoice-record/Main.vue")
  },
  // mid-one views
  {
    path: "midone-dashboard",
    component: () => import("./midone/views/dashboard/Main.vue")
  },
  {
    path: "midone-inbox",
    component: () => import("./midone/views/inbox/Main.vue")
  },
  {
    path: "midone-file-manager",
    component: () => import("./midone/views/file-manager/Main.vue")
  },
  {
    path: "midone-point-of-sale",
    component: () => import("./midone/views/point-of-sale/Main.vue")
  },
  {
    path: "midone-chat",
    component: () => import("./midone/views/chat/Main.vue")
  },
  {
    path: "midone-post",
    component: () => import("./midone/views/post/Main.vue")
  },
  {
    path: "midone-crud-data-list",
    component: () => import("./midone/views/crud-data-list/Main.vue")
  },
  {
    path: "midone-crud-form",
    component: () => import("./midone/views/crud-form/Main.vue")
  },
  {
    path: "midone-users-layout-1",
    component: () => import("./midone/views/users-layout-1/Main.vue")
  },
  {
    path: "midone-users-layout-2",
    component: () => import("./midone/views/users-layout-2/Main.vue")
  },
  {
    path: "midone-users-layout-3",
    component: () => import("./midone/views/users-layout-3/Main.vue")
  },
  {
    path: "midone-profile-overview-1",
    component: () => import("./midone/views/profile-overview-1/Main.vue")
  },
  {
    path: "midone-profile-overview-2",
    component: () => import("./midone/views/profile-overview-2/Main.vue")
  },
  {
    path: "midone-profile-overview-3",
    component: () => import("./midone/views/profile-overview-3/Main.vue")
  },
  {
    path: "midone-wizard-layout-1",
    component: () => import("./midone/views/wizard-layout-1/Main.vue")
  },
  {
    path: "midone-wizard-layout-2",
    component: () => import("./midone/views/wizard-layout-2/Main.vue")
  },
  {
    path: "midone-wizard-layout-3",
    component: () => import("./midone/views/wizard-layout-3/Main.vue")
  },
  {
    path: "midone-blog-layout-1",
    component: () => import("./midone/views/blog-layout-1/Main.vue")
  },
  {
    path: "midone-blog-layout-2",
    component: () => import("./midone/views/blog-layout-2/Main.vue")
  },
  {
    path: "midone-blog-layout-3",
    component: () => import("./midone/views/blog-layout-3/Main.vue")
  },
  {
    path: "midone-pricing-layout-1",
    component: () => import("./midone/views/pricing-layout-1/Main.vue")
  },
  {
    path: "midone-pricing-layout-2",
    component: () => import("./midone/views/pricing-layout-2/Main.vue")
  },
  {
    path: "midone-invoice-layout-1",
    component: () => import("./midone/views/invoice-layout-1/Main.vue")
  },
  {
    path: "midone-invoice-layout-2",
    component: () => import("./midone/views/invoice-layout-2/Main.vue")
  },
  {
    path: "midone-faq-layout-1",
    component: () => import("./midone/views/faq-layout-1/Main.vue")
  },
  {
    path: "midone-faq-layout-2",
    component: () => import("./midone/views/faq-layout-2/Main.vue")
  },
  {
    path: "midone-faq-layout-3",
    component: () => import("./midone/views/faq-layout-3/Main.vue")
  },
  {
    path: "midone-update-profile",
    component: () => import("./midone/views/update-profile/Main.vue")
  },
  {
    path: "midone-change-password",
    component: () => import("./midone/views/change-password/Main.vue")
  },
  {
    path: "midone-regular-table",
    component: () => import("./midone/views/regular-table/Main.vue")
  },
  {
    path: "midone-tabulator",
    component: () => import("./midone/views/tabulator/Main.vue")
  },
  {
    path: "midone-accordion",
    component: () => import("./midone/views/accordion/Main.vue")
  },
  {
    path: "midone-button",
    component: () => import("./midone/views/button/Main.vue")
  },
  {
    path: "midone-modal",
    component: () => import("./midone/views/modal/Main.vue")
  },
  {
    path: "midone-alert",
    component: () => import("./midone/views/alert/Main.vue")
  },
  {
    path: "midone-progress-bar",
    component: () => import("./midone/views/progress-bar/Main.vue")
  },
  {
    path: "midone-tooltip",
    component: () => import("./midone/views/tooltip/Main.vue")
  },
  {
    path: "midone-dropdown",
    component: () => import("./midone/views/dropdown/Main.vue")
  },
  {
    path: "midone-toast",
    component: () => import("./midone/views/toast/Main.vue")
  },
  {
    path: "midone-typography",
    component: () => import("./midone/views/typography/Main.vue")
  },
  {
    path: "midone-icon",
    component: () => import("./midone/views/icon/Main.vue")
  },
  {
    path: "midone-loading-icon",
    component: () => import("./midone/views/loading-icon/Main.vue")
  },
  {
    path: "midone-regular-form",
    component: () => import("./midone/views/regular-form/Main.vue")
  },
  {
    path: "midone-datepicker",
    component: () => import("./midone/views/datepicker/Main.vue")
  },
  {
    path: "midone-tail-select",
    component: () => import("./midone/views/tail-select/Main.vue")
  },
  {
    path: "midone-file-upload",
    component: () => import("./midone/views/file-upload/Main.vue")
  },
  {
    path: "midone-wysiwyg-editor",
    component: () => import("./midone/views/wysiwyg-editor/Main.vue")
  },
  {
    path: "midone-validation",
    component: () => import("./midone/views/validation/Main.vue")
  },
  {
    path: "midone-chart",
    component: () => import("./midone/views/chart/Main.vue")
  },
  {
    path: "midone-slider",
    component: () => import("./midone/views/slider/Main.vue")
  },
  {
    path: "midone-image-zoom",
    component: () => import("./midone/views/image-zoom/Main.vue")
  }
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/side-menu/Main.vue")
  },
  {
    path: "/simple-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/simple-menu/Main.vue")
  },
  {
    path: "/top-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/top-menu/Main.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/login/Main.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/register/Main.vue")
  },
  { path: "/error-page", name: "error-page", component: ErrorPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage }
];

export default createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});
