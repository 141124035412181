import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "HomeIcon",
      to: "dashboard",
      title: "儀錶板"
    },
    // {
    //   icon: "fa-columns",
    //   title: "選單安排",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "/dashboard",
    //       title: "側邊選單",
    //       ignore: true
    //     },
    //     {
    //       icon: "",
    //       to: "/simple-menu/dashboard",
    //       title: "簡易選單",
    //       ignore: true
    //     },
    //     {
    //       icon: "",
    //       to: "/top-menu/dashboard",
    //       title: "上方選單",
    //       ignore: true
    //     }
    //   ]
    // },
    {
      icon: "SettingsIcon",
      to: "configuration",
      title: "系統配置"
    },
    {
      icon: "UserIcon",
      to: "user",
      title: "用戶管理",
      subNodes: [
        // {
        //   icon: "fa-magic",
        //   to: "user-wizard",
        //   title: "用戶註冊精靈"
        // },
        {
          icon: "UsersIcon",
          to: "role",
          title: "角色管理"
        },
        {
          icon: "UserIcon",
          to: "user",
          title: "用戶管理"
        }
      ]
    },
    {
      icon: "ActivityIcon",
      to: "action-log",
      title: "操作紀錄"
    },

    // {
    //   icon: "FlagIcon",
    //   to: "banner",
    //   title: "廣告管理"
    // },
    // {
    //   icon: "ImageIcon",
    //   to: "album",
    //   title: "相簿管理"
    // },
    // {
    //   icon: "fa-list",
    //   to: "attribute",
    //   title: "屬性管理"
    // },
    {
      icon: "fa-store-alt",
      to: "channel",
      title: "通路管理"
    },

    {
      icon: "fa-file-invoice",
      to: "invoiceRecord",
      title: "發票登錄記錄"
    },

    {
      icon: "fa-dice",
      to: "category",
      title: "抽獎管理",
      subNodes: [
        {
          icon: "fa-sitemap",
          to: "category",
          title: "抽獎類別管理"
        },
        {
          icon: "fa-gift",
          to: "product",
          title: "獎項管理"
        },
        {
          icon: "fa-money-bill-alt",
          to: "lottery",
          title: "抽獎作業"
        },
        {
          icon: "fa-users",
          to: "winner",
          title: "得獎名單"
        }
      ]
    }
  ]
};

export default sitemap;
